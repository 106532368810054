.header-search {
    min-width: 18rem !important;
}

.aside-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $border;
}

.header {
    background: $white;
    padding-top: .75rem;
    padding-bottom: .75rem;
    border-bottom: 1px solid $border;
}

@media print {
    .header {
        display: none;
    }
}

.header {
    .dropdown-menu {
        margin-top: 0;
    }
    .mega-menu {
        width: 350px;
    }
    .nav-tabs {
        margin: 0;
    }
}

.header-right-icons {
    a {
        padding-top: 9px;
    }
    .dropdown-menu a {
        padding: 12px;
    }
    .profile-1 a {
        padding-top: 5px;
    }
}

.header-brand {
    color: inherit;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.6rem;
}

.app-header .header-brand {
    color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    min-width: 200px;
    text-align: center;
    .header-brand-img {
        margin-right: 0;
    }
}

.header-brand:hover {
    color: inherit;
    text-decoration: none;
}

.header-brand-img {
    line-height: 2rem;
    vertical-align: middle;
    width: auto;
}

.header-avatar {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
}

.header-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1rem;
}

@media (max-width: 991px) {
    .header .form-inline {
        margin-right: -2px;
    }
}

@media (max-width: 991px) {
    .header-search-icon {
        display: none !important;
    }
    .sidebar-mini.sidenav-toggled {
        .side-header {
            display: none;
        }
        .mobile-header .app-sidebar__toggle {
            display: block;
        }
    }
    .header.hor-header {
        position: fixed;
        border-bottom: 1px solid $border;
        width: 100%;
        z-index: 999;
        top: 0;
    }
    .header.hor-header {
        .header-brand-img {
            &.light-logo {
                display: none;
            }
            &.desktop-logo {
                display: none;
                margin: 0 auto;
            }
            &.light-logo1 {
                display: block;
            }
        }
        .header-brand1 {
            width: 100%;
            margin: 0 auto;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            margin-top: 0 !important;
        }
    }
}
@media (max-width: 991px) {
    .header-brand-img {
        margin-left: 1.5rem;
        margin: 3px auto;
    }
    .side-header {
        display: none !important;
    }
}

@media (max-width: 992px) and (min-width: 768px) {
    // .hor-header .header-brand-img {
    //     &.desktop-logo {
    //         display: block;
    //         margin-left: 0rem;
    //         margin-top: 2px;
    //     }
    //     &.light-logo {
    //         display: none;
    //         margin-left: 3rem;
    //         margin-top: 2px;
    //     }
    // }
    .animated-arrow {
        cursor: pointer;
        padding: 25px 35px 16px 0px !important;
        margin: 11px 0 0 15px;
    }
}

@media (max-width: 568px) {
    .mobile-header {
        .profile-1 .nav-link {
            padding-left: 0;
            padding-right: 0 !important;
        }
        .header-brand-img {
            margin-left: 0rem;
            margin-top: 2px;
        }
    }
}

@media (min-width: 768px) {
    .mobile-header {
        display: none;
    }
    .app-content .side-app {
        padding-top: 0px !important;
    }
}

.header {
    .form-inline {
        .form-control {
            border-color: rgba(225, 225, 225, 0.1);
            padding-left: 20px;
            width: 220px;
            padding-right: 40px;
            background: rgba(225, 225, 225, 0.3);
            color: $black !important;
        }
        .btn {
            border-radius: 0;
            padding: 5px 15px 6px 15px;
            border: 2px solid transparent;
            box-shadow: none;
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
            background: transparent;
            color: $white;
            margin-left: -56px;
            font-size: 15px;
        }
    }
    .header-brand-img.mobile-view-logo {
        display: none;
    }
    .dropdown-menu {
        padding: 0;
        border: 0;
    }
    .bg-image {
        background: linear-gradient(to right bottom, rgba(132, 65, 246, 0.7), rgba(212, 40, 234, 0.7));
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .bg-image-2 {
        background: linear-gradient(to right bottom, rgba(251, 196, 52, 0.7), rgba(246, 107, 78, 0.7));
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .bg-image-3 {
        background: linear-gradient(to right bottom, rgba(98, 251, 92, 0.6), rgba(33, 165, 6, 0.6));
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .bg-image a:hover {
        background: transparent !important;
    }
}

@media (max-width: 767.98px) and (min-width: 576px) {
    .header .form-inline .search-element {
        position: absolute;
        top: 8px;
        left: 10px;
        right: 10px;
        z-index: 892;
        display: none;
    }
    body.search-show .header .form-inline .search-element {
        display: block;
    }
    .header .navsearch i {
        font-size: 20px;
        color: $white;
    }
    .search-element .form-control {
        float: left;
        width: 100% !important;
        height: 40px;
        margin: 0 auto;
        background: $white !important;
        color: $black;
    }
    .search-show .search-element {
        display: block;
        left: 0 !important;
        top: 0 !important;
        right: 0;
        width: 100%;
        padding: 10px 13px;
    }
    .header {
        .form-inline .form-control::-webkit-input-placeholder {
            color: #76839a;
        }
        .navsearch i {
            font-size: 20px;
            color: $white;
            padding-top: 5px;
        }
        .form-inline .btn {
            color: #46494a !important;
            padding: 8px 15px 6px 15px;
        }
    }
}

@media (max-width: 568px) {
    .header .input-icon .form-control {
        display: block !important;
    }
    .page-main .header {
        top: 0px !important;
    }
}

.header-1 {
    .navbar .nav-link.nav-link-lg i {
        font-size: 1.1rem;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        color: $default-color;
        line-height: 32px;
        margin: 0 auto;
        text-align: center;
    }
    .nav-link .badge {
        position: absolute;
        top: 3px;
        right: 6px;
        width: 1rem;
        height: 1rem;
        font-size: 11px;
        text-align: center !important;
        padding: 0.25em 0.4em;
        line-height: 1;
    }
    .badge {
        border-radius: 50%;
    }
    .form-inline .btn {
        border-radius: 0;
        padding: 5px 15px 6px 15px;
        border: 2px solid transparent;
        box-shadow: none;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        background: transparent;
        color: #b4bdce;
        margin-left: -50px;
        font-size: 15px;
        position: inherit;
        margin-top: 3px;
    }
    .form-control.header-search {
        color: #b4bdce;
    }
    .form-inline .form-control::-webkit-input-placeholder {
        color: #76839a;
    }
    .animated-arrow span {
        display: none;
    }
}

.animated-arrow span {
    &:before,
    &:after {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .header-1 .animated-arrow span {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: $default-color;
        position: absolute;
        display: block;
        content: '';
    }
    .animated-arrow span {
        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }
    .header-1 .animated-arrow span {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: $default-color;
        position: absolute;
        display: block;
        content: '';
    }
    .animated-arrow span {
        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }
    .header-1 {
        .hor-toggle.animated-arrow {
            padding: 83px 35px 16px 20px !important;
            margin: 11px 0 0 0px !important;
        }
        .animated-arrow {
            cursor: pointer;
            margin: 0px 0 0 -11px;
            position: initial;
        }
    }
    .header-2 .animated-arrow {
        cursor: pointer;
        margin: 0px 0 0 -11px;
        position: initial;
    }
    .header-1 .form-inline .form-control,
    .header-2 .form-inline .form-control {
        width: 100PX !important;
    }
    .header-1 .header-search,
    .header-2 .header-search {
        min-width: auto !important;
    }
    .header-1 {
        .navbar .nav-link.nav-link-lg i {
            font-size: 1.1rem;
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 50%;
            color: $default-color;
            line-height: 39px;
            margin: 0 auto;
            text-align: center;
            padding: 0px 42px 0px 10px;
        }
        .dropdown-menu {
            box-shadow: 0 6px 12px 0 $black-05;
        }
        .nav-link .badge {
            position: absolute;
            top: 4px;
            width: 1rem;
            height: 1rem;
            font-size: 11px;
            text-align: center !important;
            padding: 0.25em 0.4em;
            line-height: 1;
        }
    }
}

.header {
    &.header-1 {
        .nav-link.icon i {
            color: #5b6e88 !important;
        }
        .dropdown-menu {
            box-shadow: 0 6px 12px 0 $black-05;
            top: 0 !important;
        }
    }
    &.header-2 {
        .dropdown-menu {
            box-shadow: 0 6px 12px 0 $black-05;
        }
        .nav-link {
            .badge {
                position: absolute;
                top: 2px;
                right: 8px;
                width: 1rem;
                height: 1rem;
                font-size: 11px;
                text-align: center !important;
                padding: 0.25em 0.4em;
                line-height: 1;
                border-radius: 50%;
            }
            i {
                display: block;
                text-align: center;
                font-size: 20px;
                color: $default-color;
                margin-top: 5px;
            }
        }
        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 0.9rem;
            padding-left: 0.9rem;
        }
        .form-inline {
            .btn {
                border-radius: 0;
                padding: 5px 15px 6px 15px;
                border: 2px solid transparent;
                box-shadow: none;
                border-bottom-right-radius: 3px;
                border-top-right-radius: 3px;
                background: transparent;
                color: #b4bdce;
                margin-left: -45px;
                font-size: 15px;
                position: absolute;
                margin-top: 4px;
            }
            .form-control::-webkit-input-placeholder {
                color: #76839a;
            }
        }
        .form-control.header-search {
            background: rgba(225, 225, 225, 0.4);
            border: 1px solid rgba(225, 225, 225, 0.1);
            color: $white;
            padding: 0.375rem 0.75rem;
            margin-top: 4px;
        }
    }
}

@media only screen and (max-width: 991px) {
    .header-1 .animated-arrow span {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: $default-color;
        color: $default-color;
        position: absolute;
        display: block;
        content: '';
        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            color: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }
}


/*--header-2--*/

@media only screen and (max-width: 991px) {
    .header-2 {
        .animated-arrow {
            cursor: pointer;
            padding: 8px 33px 3px 0px;
            margin: 11px 0 0 -11px;
            span {
                cursor: pointer;
                height: 2px;
                width: 17px;
                background: $default-color;
                color: $default-color;
                position: absolute;
                display: block;
                content: '';
                &:before,
                &:after {
                    cursor: pointer;
                    height: 2px;
                    width: 17px;
                    background: $default-color;
                    color: $default-color;
                    position: absolute;
                    display: block;
                    content: '';
                }
            }
        }
        .nav-link .badge {
            position: absolute;
            top: -1px;
            right: 6px;
            width: 1rem;
            height: 1rem;
            font-size: 11px;
            text-align: center !important;
            padding: 0.25em 0.4em;
            line-height: 1;
            border-radius: 50%;
        }
        .navbar-nav .dropdown-menu {
            position: absolute;
            float: right;
        }
        .dropdown-menu.dropdown-menu-right {
            position: absolute;
            transform: translate3d(-98px, 19px, -16px);
            top: 24px;
            left: 0px;
            will-change: transform;
        }
    }
}

@media only screen and (max-width: 460px) {
    .header-2 .navbar-nav .envelope {
        display: none !important;
    }
}


/*--header-3--*/

.header-3 {
    .nav-link {
        .badge {
            position: absolute;
            top: -10px;
            right: 10px;
            width: 1rem;
            height: 1rem;
            font-size: 11px;
            text-align: center !important;
            padding: 0.25em 0.4em;
            line-height: 1;
            border-radius: 50%;
        }
        i {
            display: block;
            text-align: center;
            font-size: 20px;
            position: relative;
            color: $default-color;
        }
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.9rem;
        padding-left: 0.9rem;
    }
    .form-inline {
        .btn {
            border-radius: 0;
            padding: 5px 15px 6px 15px;
            border: 2px solid transparent;
            box-shadow: none;
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
            background: transparent;
            color: $default-color;
            margin-left: -56px;
            font-size: 15px;
        }
        .form-control::-webkit-input-placeholder {
            color: #76839a;
        }
    }
    .form-control.header-search {
        background: rgba(225, 225, 225, 0.4);
        border: 1px solid rgba(225, 225, 225, 0.1);
        color: $white;
        padding: 0.375rem 0.75rem;
        margin-top: 4px;
    }
}

@media only screen and (max-width: 991px) {
    .header-3 {
        .animated-arrow {
            cursor: pointer;
            padding: 32px 33px 3px 0px;
            margin: 11px 0 0 -11px;
            span {
                cursor: pointer;
                height: 2px;
                width: 17px;
                background: $default-color;
                color: $default-color;
                position: absolute;
                display: block;
                content: '';
                &:before,
                &:after {
                    cursor: pointer;
                    height: 2px;
                    width: 17px;
                    background: $default-color;
                    color: $default-color;
                    position: absolute;
                    display: block;
                    content: '';
                }
            }
        }
        .nav-link .badge {
            position: absolute;
            top: -14px;
            right: 6px;
            width: 1rem;
            height: 1rem;
            font-size: 11px;
            text-align: center !important;
            padding: 0.25em 0.4em;
            line-height: 1;
            border-radius: 50%;
        }
        .navbar-nav .dropdown-menu {
            position: absolute;
            float: right;
        }
        .dropdown-menu.dropdown-menu-right {
            position: absolute;
            transform: translate3d(-98px, 19px, -16px);
            top: 24px;
            left: 0px;
            will-change: transform;
        }
    }
}

@media only screen and (max-width: 460px) {
    .header-3 .navbar-nav .envelope {
        display: none !important;
    }
}

.header-style .header-brand {
    margin-left: 0 !important;
}

.default-header .form-inline .form-control,
.header2 .form-inline .form-control,
.header3 .form-inline .form-control {
    background: #f6f6fb !important;
}

@media only screen and (max-width: 991px) {
    .page-header {
        padding: 4px;
    }
    .app-header {
        box-shadow: none !important;
        position: fixed !important;
    }
    .page {
        position: inherit;
        background: none;
    }
    body {
        background-color: #f6f6fb;
    }
    .page-main {
        z-index: inherit;
    }
}

@media only screen and (min-width: 768px) {
    .header .form-inline .form-control {
        width: 200px;
    }
}

.hor-header {
    .header-brand1 {
        color: inherit;
        font-size: 1.25rem;
        white-space: nowrap;
        font-weight: 600;
        padding: 0;
        transition: .3s opacity;
        line-height: 2.8rem;
        margin-top: 2px;
    }
}

.header-right-icons {
    .nav-link.icon:hover {
        background: none;
        border-radius: 50px;
    }
    .profile-user:hover {
        box-shadow: none;
    }
}


/*Logo-center header */

@media (min-width: 991px) {
    .hor-topheader {
        .header-brand1 .main-logo {
            display: none;
        }
        .header-brand2 {
            margin-left: auto;
        }
    }
}

@media (max-width: 992px) and (min-width: 768px) {
    .hor-topheader .header-brand-img.main-logo {
        display: block;
        margin-left: 3rem;
        margin-top: 2px;
    }
    .color-hor-header .hor-topheader .header-brand1 .header-brand-img.main-logo,
    .gradient-hor-header .hor-topheader .header-brand1 .header-brand-img.main-logo {
        display: none;
    }
}

.hor-topheader {
    .header-search.dropdown-menu {
        box-shadow: 0 10px 15px rgba(229, 228, 230, 0.5);
        margin-top: 13px !important;
        border: 1px solid $border !important;
    }
    .dropdown-menu.show {
        display: block;
        margin: 0;
        border: 1px solid #eaf2f6;
        transform: translate3d(8px, 45px, 0px) !important;
    }
    .dropdown-menu-arrow.dropdown-menu-right {
        &:before,
        &:after {
            right: auto;
            left: 12px;
        }
    }
}

@media (max-width: 480px) {
    .app-header .header-brand,
    .app-header1 .header-brand {
        min-width: auto;
    }
    .header-right-icons .dropdown .header-toggler {
        margin-top: 9px;
    }
}

@media (max-width: 991px) {
    .header {
        border-bottom: 1px solid $white-2;
        padding-top: 0.95rem;
        padding-bottom: 0.95rem;
    }
}

.header {
    .notifications {
        .dropdown-menu {
            min-width: 18rem;
        }
    }
    .dropdown.message {
        .dropdown-menu {
            min-width: 19rem;
        }
    }
    .dropdown.notifications,
    .dropdown.message {
        .dropdown-item {
            padding: 1rem !important;
        }
    }
    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;
        top: 3.5rem !important;
        .badge {
            padding: 3px 6px;
        }
        .dropdown-item {
            border-bottom: 1px solid $border;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .dropdown-item {
        color: $default-color;
    }
    .notifications-menu,
    .message-menu {
        h5 {
            font-size: 13.5px;
            color: $default-color;
            font-weight: 500;
        }
        span {
            font-size: 12px;
            color: #76839a;
        }
    }
    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;
        .badge {
            padding: 3px 6px;
        }
        .dropdown-item {
            border-bottom: 1px solid $border;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .message-menu .wd-90p {
        width: 90%;
    }
    .profile-1 {
        .dropdown-item {
            padding: .75rem 1.75rem !important;
            .dropdown-icon {
                font-size: 16px;
                color: $primary-1;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: 5px;
                    bottom: 0;
                    border-radius: 50%;
                    right: 3px;
                    background: rgba($primary-1, 0);
                }
            }
        }
    }
}

.responsive-navbar {
    .notifications-menu,
    .message-menu {
        h5 {
            font-size: 13.5px;
            color: $default-color;
            font-weight: 500;
        }
        span {
            font-size: 12px;
            color: #76839a;
        }
    }
    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;
        .badge {
            padding: 4px 8px;
        }
        .dropdown-item {
            border-bottom: 1px solid $border;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .message-menu .wd-90p {
        width: 90%;
    }
    .profile-1 {
        .dropdown-item {
            padding: .75rem 1.75rem !important;
            .dropdown-icon {
                font-size: 16px;
                color: $primary-1;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: 5px;
                    bottom: 0;
                    border-radius: 50%;
                    right: 3px;
                    background: rgba($primary-1, 0);
                }
            }
        }
    }
}

.header-1,
.header-2,
.header-1.header-style {
    .header-brand-img.logo {
        display: none;
    }
}

.header {
    &.header-1.header-style {
        .dropdown-menu {
            top: inherit !important;
        }
    }
}

.dropdown .avatar.profile-user {
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
}
@media only screen and (max-width: 460px) {
    .header-2 .navbar-nav .envelope {
        display: none !important;
    }
    .profile-cover__img .profile-img-1>img{
        margin-left: -29px;
    }
}
