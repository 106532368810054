.icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    >li {
        -ms-flex: 1 0 3rem;
        flex: 1 0 3rem;
    }
}

.icons-list-wrap {
    overflow: hidden;
}

.icons-list-item {
    text-align: center;
    height: 3rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #e9ebfa;
    margin: 4px;
    border-radius: 50px;
    // background: #f6f6fb;
    i {
        font-size: 1.05rem;
        color: #656585;
    }
}


/*-----Icon input-----*/

.input-icon {
    position: relative;
}

.input-icon-addon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 2.5rem;
    pointer-events: none;
    color: $white;
    &.search-icon {
        right: auto !important;
        left: 0 !important;
        color: #636262 !important;
    }
    &:last-child {
        left: auto;
        right: 0;
    }
}

.icon-absolute {
    position: absolute;
    display: block;
    bottom: 10px;
    right: 10px;
    padding: 20px 0;
    text-align: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
}

@media (min-width: 991px) and (max-width: 1024px) {
    .icon-absolute {
        display: none;
    }
}


/*------ Icons List ------*/

.browser {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    background: no-repeat center/100% 100%;
    vertical-align: bottom;
    font-style: normal;
}

.browser-android-browser {
    background-image: url("../images/browsers/android-browser.svg");
}

.browser-aol-explorer {
    background-image: url("../images/browsers/aol-explorer.svg");
}

.browser-blackberry {
    background-image: url("../images/browsers/blackberry.svg");
}

.browser-camino {
    background-image: url("../images/browsers/camino.svg");
}

.browser-chrome {
    background-image: url("../images/browsers/chrome.svg");
}

.browser-chromium {
    background-image: url("../images/browsers/chromium.svg");
}

.browser-dolphin {
    background-image: url("../images/browsers/dolphin.svg");
}

.browser-edge {
    background-image: url("../images/browsers/edge.svg");
}

.browser-firefox {
    background-image: url("../images/browsers/firefox.svg");
}

.browser-ie {
    background-image: url("../images/browsers/ie.svg");
}

.browser-maxthon {
    background-image: url("../images/browsers/maxthon.svg");
}

.browser-mozilla {
    background-image: url("../images/browsers/mozilla.svg");
}

.browser-netscape {
    background-image: url("../images/browsers/netscape.svg");
}

.browser-opera {
    background-image: url("../images/browsers/opera.svg");
}

.browser-safari {
    background-image: url("../images/browsers/safari.svg");
}

.browser-sleipnir {
    background-image: url("../images/browsers/sleipnir.svg");
}

.browser-uc-browser {
    background-image: url("../images/browsers/uc-browser.svg");
}

.browser-vivaldi {
    background-image: url("../images/browsers/vivaldi.svg");
}

.flag {
    width: 1.6rem;
    height: 1.2rem;
    display: inline-block;
    background: no-repeat center/100% 100%;
    vertical-align: bottom;
    font-style: normal;
    box-shadow: 0 0 1px 1px $black-1;
    border-radius: 2px;
}

.flag-ad {
    background-image: url("../images/flags/ad.svg");
}

.flag-ae {
    background-image: url("../images/flags/ae.svg");
}

.flag-af {
    background-image: url("../images/flags/af.svg");
}

.flag-ag {
    background-image: url("../images/flags/ag.svg");
}

.flag-ai {
    background-image: url("../images/flags/ai.svg");
}

.flag-al {
    background-image: url("../images/flags/al.svg");
}

.flag-am {
    background-image: url("../images/flags/am.svg");
}

.flag-ao {
    background-image: url("../images/flags/ao.svg");
}

.flag-aq {
    background-image: url("../images/flags/aq.svg");
}

.flag-ar {
    background-image: url("../images/flags/ar.svg");
}

.flag-as {
    background-image: url("../images/flags/as.svg");
}

.flag-at {
    background-image: url("../images/flags/at.svg");
}

.flag-au {
    background-image: url("../images/flags/au.svg");
}

.flag-aw {
    background-image: url("../images/flags/aw.svg");
}

.flag-ax {
    background-image: url("../images/flags/ax.svg");
}

.flag-az {
    background-image: url("../images/flags/az.svg");
}

.flag-ba {
    background-image: url("../images/flags/ba.svg");
}

.flag-bb {
    background-image: url("../images/flags/bb.svg");
}

.flag-bd {
    background-image: url("../images/flags/bd.svg");
}

.flag-be {
    background-image: url("../images/flags/be.svg");
}

.flag-bf {
    background-image: url("../images/flags/bf.svg");
}

.flag-bg {
    background-image: url("../images/flags/bg.svg");
}

.flag-bh {
    background-image: url("../images/flags/bh.svg");
}

.flag-bi {
    background-image: url("../images/flags/bi.svg");
}

.flag-bj {
    background-image: url("../images/flags/bj.svg");
}

.flag-bl {
    background-image: url("../images/flags/bl.svg");
}

.flag-bm {
    background-image: url("../images/flags/bm.svg");
}

.flag-bn {
    background-image: url("../images/flags/bn.svg");
}

.flag-bo {
    background-image: url("../images/flags/bo.svg");
}

.flag-bq {
    background-image: url("../images/flags/bq.svg");
}

.flag-br {
    background-image: url("../images/flags/br.svg");
}

.flag-bs {
    background-image: url("../images/flags/bs.svg");
}

.flag-bt {
    background-image: url("../images/flags/bt.svg");
}

.flag-bv {
    background-image: url("../images/flags/bv.svg");
}

.flag-bw {
    background-image: url("../images/flags/bw.svg");
}

.flag-by {
    background-image: url("../images/flags/by.svg");
}

.flag-bz {
    background-image: url("../images/flags/bz.svg");
}

.flag-ca {
    background-image: url("../images/flags/ca.svg");
}

.flag-cc {
    background-image: url("../images/flags/cc.svg");
}

.flag-cd {
    background-image: url("../images/flags/cd.svg");
}

.flag-cf {
    background-image: url("../images/flags/cf.svg");
}

.flag-cg {
    background-image: url("../images/flags/cg.svg");
}

.flag-ch {
    background-image: url("../images/flags/ch.svg");
}

.flag-ci {
    background-image: url("../images/flags/ci.svg");
}

.flag-ck {
    background-image: url("../images/flags/ck.svg");
}

.flag-cl {
    background-image: url("../images/flags/cl.svg");
}

.flag-cm {
    background-image: url("../images/flags/cm.svg");
}

.flag-cn {
    background-image: url("../images/flags/cn.svg");
}

.flag-co {
    background-image: url("../images/flags/co.svg");
}

.flag-cr {
    background-image: url("../images/flags/cr.svg");
}

.flag-cu {
    background-image: url("../images/flags/cu.svg");
}

.flag-cv {
    background-image: url("../images/flags/cv.svg");
}

.flag-cw {
    background-image: url("../images/flags/cw.svg");
}

.flag-cx {
    background-image: url("../images/flags/cx.svg");
}

.flag-cy {
    background-image: url("../images/flags/cy.svg");
}

.flag-cz {
    background-image: url("../images/flags/cz.svg");
}

.flag-de {
    background-image: url("../images/flags/de.svg");
}

.flag-dj {
    background-image: url("../images/flags/dj.svg");
}

.flag-dk {
    background-image: url("../images/flags/dk.svg");
}

.flag-dm {
    background-image: url("../images/flags/dm.svg");
}

.flag-do {
    background-image: url("../images/flags/do.svg");
}

.flag-dz {
    background-image: url("../images/flags/dz.svg");
}

.flag-ec {
    background-image: url("../images/flags/ec.svg");
}

.flag-ee {
    background-image: url("../images/flags/ee.svg");
}

.flag-eg {
    background-image: url("../images/flags/eg.svg");
}

.flag-eh {
    background-image: url("../images/flags/eh.svg");
}

.flag-er {
    background-image: url("../images/flags/er.svg");
}

.flag-es {
    background-image: url("../images/flags/es.svg");
}

.flag-et {
    background-image: url("../images/flags/et.svg");
}

.flag-eu {
    background-image: url("../images/flags/eu.svg");
}

.flag-fi {
    background-image: url("../images/flags/fi.svg");
}

.flag-fj {
    background-image: url("../images/flags/fj.svg");
}

.flag-fk {
    background-image: url("../images/flags/fk.svg");
}

.flag-fm {
    background-image: url("../images/flags/fm.svg");
}

.flag-fo {
    background-image: url("../images/flags/fo.svg");
}

.flag-fr {
    background-image: url("../images/flags/fr.svg");
}

.flag-ga {
    background-image: url("../images/flags/ga.svg");
}

.flag-gb-eng {
    background-image: url("../images/flags/gb-eng.svg");
}

.flag-gb-nir {
    background-image: url("../images/flags/gb-nir.svg");
}

.flag-gb-sct {
    background-image: url("../images/flags/gb-sct.svg");
}

.flag-gb-wls {
    background-image: url("../images/flags/gb-wls.svg");
}

.flag-gb {
    background-image: url("../images/flags/gb.svg");
}

.flag-gd {
    background-image: url("../images/flags/gd.svg");
}

.flag-ge {
    background-image: url("../images/flags/ge.svg");
}

.flag-gf {
    background-image: url("../images/flags/gf.svg");
}

.flag-gg {
    background-image: url("../images/flags/gg.svg");
}

.flag-gh {
    background-image: url("../images/flags/gh.svg");
}

.flag-gi {
    background-image: url("../images/flags/gi.svg");
}

.flag-gl {
    background-image: url("../images/flags/gl.svg");
}

.flag-gm {
    background-image: url("../images/flags/gm.svg");
}

.flag-gn {
    background-image: url("../images/flags/gn.svg");
}

.flag-gp {
    background-image: url("../images/flags/gp.svg");
}

.flag-gq {
    background-image: url("../images/flags/gq.svg");
}

.flag-gr {
    background-image: url("../images/flags/gr.svg");
}

.flag-gs {
    background-image: url("../images/flags/gs.svg");
}

.flag-gt {
    background-image: url("../images/flags/gt.svg");
}

.flag-gu {
    background-image: url("../images/flags/gu.svg");
}

.flag-gw {
    background-image: url("../images/flags/gw.svg");
}

.flag-gy {
    background-image: url("../images/flags/gy.svg");
}

.flag-hk {
    background-image: url("../images/flags/hk.svg");
}

.flag-hm {
    background-image: url("../images/flags/hm.svg");
}

.flag-hn {
    background-image: url("../images/flags/hn.svg");
}

.flag-hr {
    background-image: url("../images/flags/hr.svg");
}

.flag-ht {
    background-image: url("../images/flags/ht.svg");
}

.flag-hu {
    background-image: url("../images/flags/hu.svg");
}

.flag-id {
    background-image: url("../images/flags/id.svg");
}

.flag-ie {
    background-image: url("../images/flags/ie.svg");
}

.flag-il {
    background-image: url("../images/flags/il.svg");
}

.flag-im {
    background-image: url("../images/flags/im.svg");
}

.flag-in {
    background-image: url("../images/flags/in.svg");
}

.flag-io {
    background-image: url("../images/flags/io.svg");
}

.flag-iq {
    background-image: url("../images/flags/iq.svg");
}

.flag-ir {
    background-image: url("../images/flags/ir.svg");
}

.flag-is {
    background-image: url("../images/flags/is.svg");
}

.flag-it {
    background-image: url("../images/flags/it.svg");
}

.flag-je {
    background-image: url("../images/flags/je.svg");
}

.flag-jm {
    background-image: url("../images/flags/jm.svg");
}

.flag-jo {
    background-image: url("../images/flags/jo.svg");
}

.flag-jp {
    background-image: url("../images/flags/jp.svg");
}

.flag-ke {
    background-image: url("../images/flags/ke.svg");
}

.flag-kg {
    background-image: url("../images/flags/kg.svg");
}

.flag-kh {
    background-image: url("../images/flags/kh.svg");
}

.flag-ki {
    background-image: url("../images/flags/ki.svg");
}

.flag-km {
    background-image: url("../images/flags/km.svg");
}

.flag-kn {
    background-image: url("../images/flags/kn.svg");
}

.flag-kp {
    background-image: url("../images/flags/kp.svg");
}

.flag-kr {
    background-image: url("../images/flags/kr.svg");
}

.flag-kw {
    background-image: url("../images/flags/kw.svg");
}

.flag-ky {
    background-image: url("../images/flags/ky.svg");
}

.flag-kz {
    background-image: url("../images/flags/kz.svg");
}

.flag-la {
    background-image: url("../images/flags/la.svg");
}

.flag-lb {
    background-image: url("../images/flags/lb.svg");
}

.flag-lc {
    background-image: url("../images/flags/lc.svg");
}

.flag-li {
    background-image: url("../images/flags/li.svg");
}

.flag-lk {
    background-image: url("../images/flags/lk.svg");
}

.flag-lr {
    background-image: url("../images/flags/lr.svg");
}

.flag-ls {
    background-image: url("../images/flags/ls.svg");
}

.flag-lt {
    background-image: url("../images/flags/lt.svg");
}

.flag-lu {
    background-image: url("../images/flags/lu.svg");
}

.flag-lv {
    background-image: url("../images/flags/lv.svg");
}

.flag-ly {
    background-image: url("../images/flags/ly.svg");
}

.flag-ma {
    background-image: url("../images/flags/ma.svg");
}

.flag-mc {
    background-image: url("../images/flags/mc.svg");
}

.flag-md {
    background-image: url("../images/flags/md.svg");
}

.flag-me {
    background-image: url("../images/flags/me.svg");
}

.flag-mf {
    background-image: url("../images/flags/mf.svg");
}

.flag-mg {
    background-image: url("../images/flags/mg.svg");
}

.flag-mh {
    background-image: url("../images/flags/mh.svg");
}

.flag-mk {
    background-image: url("../images/flags/mk.svg");
}

.flag-ml {
    background-image: url("../images/flags/ml.svg");
}

.flag-mm {
    background-image: url("../images/flags/mm.svg");
}

.flag-mn {
    background-image: url("../images/flags/mn.svg");
}

.flag-mo {
    background-image: url("../images/flags/mo.svg");
}

.flag-mp {
    background-image: url("../images/flags/mp.svg");
}

.flag-mq {
    background-image: url("../images/flags/mq.svg");
}

.flag-mr {
    background-image: url("../images/flags/mr.svg");
}

.flag-ms {
    background-image: url("../images/flags/ms.svg");
}

.flag-mt {
    background-image: url("../images/flags/mt.svg");
}

.flag-mu {
    background-image: url("../images/flags/mu.svg");
}

.flag-mv {
    background-image: url("../images/flags/mv.svg");
}

.flag-mw {
    background-image: url("../images/flags/mw.svg");
}

.flag-mx {
    background-image: url("../images/flags/mx.svg");
}

.flag-my {
    background-image: url("../images/flags/my.svg");
}

.flag-mz {
    background-image: url("../images/flags/mz.svg");
}

.flag-na {
    background-image: url("../images/flags/na.svg");
}

.flag-nc {
    background-image: url("../images/flags/nc.svg");
}

.flag-ne {
    background-image: url("../images/flags/ne.svg");
}

.flag-nf {
    background-image: url("../images/flags/nf.svg");
}

.flag-ng {
    background-image: url("../images/flags/ng.svg");
}

.flag-ni {
    background-image: url("../images/flags/ni.svg");
}

.flag-nl {
    background-image: url("../images/flags/nl.svg");
}

.flag-no {
    background-image: url("../images/flags/no.svg");
}

.flag-np {
    background-image: url("../images/flags/np.svg");
}

.flag-nr {
    background-image: url("../images/flags/nr.svg");
}

.flag-nu {
    background-image: url("../images/flags/nu.svg");
}

.flag-nz {
    background-image: url("../images/flags/nz.svg");
}

.flag-om {
    background-image: url("../images/flags/om.svg");
}

.flag-pa {
    background-image: url("../images/flags/pa.svg");
}

.flag-pe {
    background-image: url("../images/flags/pe.svg");
}

.flag-pf {
    background-image: url("../images/flags/pf.svg");
}

.flag-pg {
    background-image: url("../images/flags/pg.svg");
}

.flag-ph {
    background-image: url("../images/flags/ph.svg");
}

.flag-pk {
    background-image: url("../images/flags/pk.svg");
}

.flag-pl {
    background-image: url("../images/flags/pl.svg");
}

.flag-pm {
    background-image: url("../images/flags/pm.svg");
}

.flag-pn {
    background-image: url("../images/flags/pn.svg");
}

.flag-pr {
    background-image: url("../images/flags/pr.svg");
}

.flag-ps {
    background-image: url("../images/flags/ps.svg");
}

.flag-pt {
    background-image: url("../images/flags/pt.svg");
}

.flag-pw {
    background-image: url("../images/flags/pw.svg");
}

.flag-py {
    background-image: url("../images/flags/py.svg");
}

.flag-qa {
    background-image: url("../images/flags/qa.svg");
}

.flag-re {
    background-image: url("../images/flags/re.svg");
}

.flag-ro {
    background-image: url("../images/flags/ro.svg");
}

.flag-rs {
    background-image: url("../images/flags/rs.svg");
}

.flag-ru {
    background-image: url("../images/flags/ru.svg");
}

.flag-rw {
    background-image: url("../images/flags/rw.svg");
}

.flag-sa {
    background-image: url("../images/flags/sa.svg");
}

.flag-sb {
    background-image: url("../images/flags/sb.svg");
}

.flag-sc {
    background-image: url("../images/flags/sc.svg");
}

.flag-sd {
    background-image: url("../images/flags/sd.svg");
}

.flag-se {
    background-image: url("../images/flags/se.svg");
}

.flag-sg {
    background-image: url("../images/flags/sg.svg");
}

.flag-sh {
    background-image: url("../images/flags/sh.svg");
}

.flag-si {
    background-image: url("../images/flags/si.svg");
}

.flag-sj {
    background-image: url("../images/flags/sj.svg");
}

.flag-sk {
    background-image: url("../images/flags/sk.svg");
}

.flag-sl {
    background-image: url("../images/flags/sl.svg");
}

.flag-sm {
    background-image: url("../images/flags/sm.svg");
}

.flag-sn {
    background-image: url("../images/flags/sn.svg");
}

.flag-so {
    background-image: url("../images/flags/so.svg");
}

.flag-sr {
    background-image: url("../images/flags/sr.svg");
}

.flag-ss {
    background-image: url("../images/flags/ss.svg");
}

.flag-st {
    background-image: url("../images/flags/st.svg");
}

.flag-sv {
    background-image: url("../images/flags/sv.svg");
}

.flag-sx {
    background-image: url("../images/flags/sx.svg");
}

.flag-sy {
    background-image: url("../images/flags/sy.svg");
}

.flag-sz {
    background-image: url("../images/flags/sz.svg");
}

.flag-tc {
    background-image: url("../images/flags/tc.svg");
}

.flag-td {
    background-image: url("../images/flags/td.svg");
}

.flag-tf {
    background-image: url("../images/flags/tf.svg");
}

.flag-tg {
    background-image: url("../images/flags/tg.svg");
}

.flag-th {
    background-image: url("../images/flags/th.svg");
}

.flag-tj {
    background-image: url("../images/flags/tj.svg");
}

.flag-tk {
    background-image: url("../images/flags/tk.svg");
}

.flag-tl {
    background-image: url("../images/flags/tl.svg");
}

.flag-tm {
    background-image: url("../images/flags/tm.svg");
}

.flag-tn {
    background-image: url("../images/flags/tn.svg");
}

.flag-to {
    background-image: url("../images/flags/to.svg");
}

.flag-tr {
    background-image: url("../images/flags/tr.svg");
}

.flag-tt {
    background-image: url("../images/flags/tt.svg");
}

.flag-tv {
    background-image: url("../images/flags/tv.svg");
}

.flag-tw {
    background-image: url("../images/flags/tw.svg");
}

.flag-tz {
    background-image: url("../images/flags/tz.svg");
}

.flag-ua {
    background-image: url("../images/flags/ua.svg");
}

.flag-ug {
    background-image: url("../images/flags/ug.svg");
}

.flag-um {
    background-image: url("../images/flags/um.svg");
}

.flag-un {
    background-image: url("../images/flags/un.svg");
}

.flag-us {
    background-image: url("../images/flags/us.svg");
}

.flag-uy {
    background-image: url("../images/flags/uy.svg");
}

.flag-uz {
    background-image: url("../images/flags/uz.svg");
}

.flag-va {
    background-image: url("../images/flags/va.svg");
}

.flag-vc {
    background-image: url("../images/flags/vc.svg");
}

.flag-ve {
    background-image: url("../images/flags/ve.svg");
}

.flag-vg {
    background-image: url("../images/flags/vg.svg");
}

.flag-vi {
    background-image: url("../images/flags/vi.svg");
}

.flag-vn {
    background-image: url("../images/flags/vn.svg");
}

.flag-vu {
    background-image: url("../images/flags/vu.svg");
}

.flag-wf {
    background-image: url("../images/flags/wf.svg");
}

.flag-ws {
    background-image: url("../images/flags/ws.svg");
}

.flag-ye {
    background-image: url("../images/flags/ye.svg");
}

.flag-yt {
    background-image: url("../images/flags/yt.svg");
}

.flag-za {
    background-image: url("../images/flags/za.svg");
}

.flag-zm {
    background-image: url("../images/flags/zm.svg");
}

.flag-zw {
    background-image: url("../images/flags/zw.svg");
}

.payment {
    width: 2rem;
    height: 1.5rem;
    display: inline-block;
    background: no-repeat center/100% 100%;
    vertical-align: bottom;
    font-style: normal;
    box-shadow: 0 0 1px 1px $black-1;
    border-radius: 2px;
}

.payment-2checkout-dark {
    background-image: url("../images/payments/2checkout-dark.svg");
}

.payment-2checkout {
    background-image: url("../images/payments/2checkout.svg");
}

.payment-alipay-dark {
    background-image: url("../images/payments/alipay-dark.svg");
}

.payment-alipay {
    background-image: url("../images/payments/alipay.svg");
}

.payment-amazon-dark {
    background-image: url("../images/payments/amazon-dark.svg");
}

.payment-amazon {
    background-image: url("../images/payments/amazon.svg");
}

.payment-americanexpress-dark {
    background-image: url("../images/payments/americanexpress-dark.svg");
}

.payment-americanexpress {
    background-image: url("../images/payments/americanexpress.svg");
}

.payment-applepay-dark {
    background-image: url("../images/payments/applepay-dark.svg");
}

.payment-applepay {
    background-image: url("../images/payments/applepay.svg");
}

.payment-bancontact-dark {
    background-image: url("../images/payments/bancontact-dark.svg");
}

.payment-bancontact {
    background-image: url("../images/payments/bancontact.svg");
}

.payment-bitcoin-dark {
    background-image: url("../images/payments/bitcoin-dark.svg");
}

.payment-bitcoin {
    background-image: url("../images/payments/bitcoin.svg");
}

.payment-bitpay-dark {
    background-image: url("../images/payments/bitpay-dark.svg");
}

.payment-bitpay {
    background-image: url("../images/payments/bitpay.svg");
}

.payment-cirrus-dark {
    background-image: url("../images/payments/cirrus-dark.svg");
}

.payment-cirrus {
    background-image: url("../images/payments/cirrus.svg");
}

.payment-clickandbuy-dark {
    background-image: url("../images/payments/clickandbuy-dark.svg");
}

.payment-clickandbuy {
    background-image: url("../images/payments/clickandbuy.svg");
}

.payment-coinkite-dark {
    background-image: url("../images/payments/coinkite-dark.svg");
}

.payment-coinkite {
    background-image: url("../images/payments/coinkite.svg");
}

.payment-dinersclub-dark {
    background-image: url("../images/payments/dinersclub-dark.svg");
}

.payment-dinersclub {
    background-image: url("../images/payments/dinersclub.svg");
}

.payment-directdebit-dark {
    background-image: url("../images/payments/directdebit-dark.svg");
}

.payment-directdebit {
    background-image: url("../images/payments/directdebit.svg");
}

.payment-discover-dark {
    background-image: url("../images/payments/discover-dark.svg");
}

.payment-discover {
    background-image: url("../images/payments/discover.svg");
}

.payment-dwolla-dark {
    background-image: url("../images/payments/dwolla-dark.svg");
}

.payment-dwolla {
    background-image: url("../images/payments/dwolla.svg");
}

.payment-ebay-dark {
    background-image: url("../images/payments/ebay-dark.svg");
}

.payment-ebay {
    background-image: url("../images/payments/ebay.svg");
}

.payment-eway-dark {
    background-image: url("../images/payments/eway-dark.svg");
}

.payment-eway {
    background-image: url("../images/payments/eway.svg");
}

.payment-giropay-dark {
    background-image: url("../images/payments/giropay-dark.svg");
}

.payment-giropay {
    background-image: url("../images/payments/giropay.svg");
}

.payment-googlewallet-dark {
    background-image: url("../images/payments/googlewallet-dark.svg");
}

.payment-googlewallet {
    background-image: url("../images/payments/googlewallet.svg");
}

.payment-ingenico-dark {
    background-image: url("../images/payments/ingenico-dark.svg");
}

.payment-ingenico {
    background-image: url("../images/payments/ingenico.svg");
}

.payment-jcb-dark {
    background-image: url("../images/payments/jcb-dark.svg");
}

.payment-jcb {
    background-image: url("../images/payments/jcb.svg");
}

.payment-klarna-dark {
    background-image: url("../images/payments/klarna-dark.svg");
}

.payment-klarna {
    background-image: url("../images/payments/klarna.svg");
}

.payment-laser-dark {
    background-image: url("../images/payments/laser-dark.svg");
}

.payment-laser {
    background-image: url("../images/payments/laser.svg");
}

.payment-maestro-dark {
    background-image: url("../images/payments/maestro-dark.svg");
}

.payment-maestro {
    background-image: url("../images/payments/maestro.svg");
}

.payment-mastercard-dark {
    background-image: url("../images/payments/mastercard-dark.svg");
}

.payment-mastercard {
    background-image: url("../images/payments/mastercard.svg");
}

.payment-monero-dark {
    background-image: url("../images/payments/monero-dark.svg");
}

.payment-monero {
    background-image: url("../images/payments/monero.svg");
}

.payment-neteller-dark {
    background-image: url("../images/payments/neteller-dark.svg");
}

.payment-neteller {
    background-image: url("../images/payments/neteller.svg");
}

.payment-ogone-dark {
    background-image: url("../images/payments/ogone-dark.svg");
}

.payment-ogone {
    background-image: url("../images/payments/ogone.svg");
}

.payment-okpay-dark {
    background-image: url("../images/payments/okpay-dark.svg");
}

.payment-okpay {
    background-image: url("../images/payments/okpay.svg");
}

.payment-paybox-dark {
    background-image: url("../images/payments/paybox-dark.svg");
}

.payment-paybox {
    background-image: url("../images/payments/paybox.svg");
}

.payment-paymill-dark {
    background-image: url("../images/payments/paymill-dark.svg");
}

.payment-paymill {
    background-image: url("../images/payments/paymill.svg");
}

.payment-payone-dark {
    background-image: url("../images/payments/payone-dark.svg");
}

.payment-payone {
    background-image: url("../images/payments/payone.svg");
}

.payment-payoneer-dark {
    background-image: url("../images/payments/payoneer-dark.svg");
}

.payment-payoneer {
    background-image: url("../images/payments/payoneer.svg");
}

.payment-paypal-dark {
    background-image: url("../images/payments/paypal-dark.svg");
}

.payment-paypal {
    background-image: url("../images/payments/paypal.svg");
}

.payment-paysafecard-dark {
    background-image: url("../images/payments/paysafecard-dark.svg");
}

.payment-paysafecard {
    background-image: url("../images/payments/paysafecard.svg");
}

.payment-payu-dark {
    background-image: url("../images/payments/payu-dark.svg");
}

.payment-payu {
    background-image: url("../images/payments/payu.svg");
}

.payment-payza-dark {
    background-image: url("../images/payments/payza-dark.svg");
}

.payment-payza {
    background-image: url("../images/payments/payza.svg");
}

.payment-ripple-dark {
    background-image: url("../images/payments/ripple-dark.svg");
}

.payment-ripple {
    background-image: url("../images/payments/ripple.svg");
}

.payment-sage-dark {
    background-image: url("../images/payments/sage-dark.svg");
}

.payment-sage {
    background-image: url("../images/payments/sage.svg");
}

.payment-sepa-dark {
    background-image: url("../images/payments/sepa-dark.svg");
}

.payment-sepa {
    background-image: url("../images/payments/sepa.svg");
}

.payment-shopify-dark {
    background-image: url("../images/payments/shopify-dark.svg");
}

.payment-shopify {
    background-image: url("../images/payments/shopify.svg");
}

.payment-skrill-dark {
    background-image: url("../images/payments/skrill-dark.svg");
}

.payment-skrill {
    background-image: url("../images/payments/skrill.svg");
}

.payment-solo-dark {
    background-image: url("../images/payments/solo-dark.svg");
}

.payment-solo {
    background-image: url("../images/payments/solo.svg");
}

.payment-square-dark {
    background-image: url("../images/payments/square-dark.svg");
}

.payment-square {
    background-image: url("../images/payments/square.svg");
}

.payment-stripe-dark {
    background-image: url("../images/payments/stripe-dark.svg");
}

.payment-stripe {
    background-image: url("../images/payments/stripe.svg");
}

.payment-switch-dark {
    background-image: url("../images/payments/switch-dark.svg");
}

.payment-switch {
    background-image: url("../images/payments/switch.svg");
}

.payment-ukash-dark {
    background-image: url("../images/payments/ukash-dark.svg");
}

.payment-ukash {
    background-image: url("../images/payments/ukash.svg");
}

.payment-unionpay-dark {
    background-image: url("../images/payments/unionpay-dark.svg");
}

.payment-unionpay {
    background-image: url("../images/payments/unionpay.svg");
}

.payment-verifone-dark {
    background-image: url("../images/payments/verifone-dark.svg");
}

.payment-verifone {
    background-image: url("../images/payments/verifone.svg");
}

.payment-verisign-dark {
    background-image: url("../images/payments/verisign-dark.svg");
}

.payment-verisign {
    background-image: url("../images/payments/verisign.svg");
}

.payment-visa-dark {
    background-image: url("../images/payments/visa-dark.svg");
}

.payment-visa {
    background-image: url("../images/payments/visa.svg");
}

.payment-webmoney-dark {
    background-image: url("../images/payments/webmoney-dark.svg");
}

.payment-webmoney {
    background-image: url("../images/payments/webmoney.svg");
}

.payment-westernunion-dark {
    background-image: url("../images/payments/westernunion-dark.svg");
}

.payment-westernunion {
    background-image: url("../images/payments/westernunion.svg");
}

.payment-worldpay-dark {
    background-image: url("../images/payments/worldpay-dark.svg");
}

.payment-worldpay {
    background-image: url("../images/payments/worldpay.svg");
}

svg {
    -ms-touch-action: none;
    touch-action: none;
}

.col-sm-3 a {
    border: 1px solid transparent;
    border-radius: 0;
    transition: all 3s ease;
    &:hover {
        border: 1px solid #ff4647;
        border-radius: 100% 60% / 30% 10%;
        background: linear-gradient(rgba(56, 123, 131, 0.7), rgba(56, 123, 131, 0.7));
    }
}


/*-----Feather icons-----*/

@font-face {
    font-family: "feather";
    src: url("../iconfonts/feather/fonts/feather-webfont.eot?t=1501841394106");
    /* IE9*/
    src: url("../iconfonts/feather/fonts/feather-webfont.eot?t=1501841394106#iefix") format("embedded-opentype"), url("../iconfonts/feather/fonts/feather-webfont.woff?t=1501841394106") format("woff"), url("../iconfonts/feather/fonts/feather-webfont.ttf?t=1501841394106") format("truetype"), url("../iconfonts/feather/fonts/feather-webfont.svg?t=1501841394106#feather") format("svg");
    /* iOS 4.1- */
}

.fe {
    font-family: 'feather' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fe-activity:before {
    content: "\e900";
}

.fe-airplay:before {
    content: "\e901";
}

.fe-alert-circle:before {
    content: "\e902";
}

.fe-alert-octagon:before {
    content: "\e903";
}

.fe-alert-triangle:before {
    content: "\e904";
}

.fe-align-center:before {
    content: "\e905";
}

.fe-align-justify:before {
    content: "\e906";
}

.fe-align-left:before {
    content: "\e907";
}

.fe-align-right:before {
    content: "\e908";
}

.fe-anchor:before {
    content: "\e909";
}

.fe-aperture:before {
    content: "\e90a";
}

.fe-arrow-down:before {
    content: "\e90b";
}

.fe-arrow-down-circle:before {
    content: "\e90c";
}

.fe-arrow-down-left:before {
    content: "\e90d";
}

.fe-arrow-down-right:before {
    content: "\e90e";
}

.fe-arrow-left:before {
    content: "\e90f";
}

.fe-arrow-left-circle:before {
    content: "\e910";
}

.fe-arrow-right:before {
    content: "\e911";
}

.fe-arrow-right-circle:before {
    content: "\e912";
}

.fe-arrow-up:before {
    content: "\e913";
}

.fe-arrow-up-circle:before {
    content: "\e914";
}

.fe-arrow-up-left:before {
    content: "\e915";
}

.fe-arrow-up-right:before {
    content: "\e916";
}

.fe-at-sign:before {
    content: "\e917";
}

.fe-award:before {
    content: "\e918";
}

.fe-bar-chart:before {
    content: "\e919";
}

.fe-bar-chart-2:before {
    content: "\e91a";
}

.fe-battery:before {
    content: "\e91b";
}

.fe-battery-charging:before {
    content: "\e91c";
}

.fe-bell:before {
    content: "\e91d";
}

.fe-bell-off:before {
    content: "\e91e";
}

.fe-bluetooth:before {
    content: "\e91f";
}

.fe-bold:before {
    content: "\e920";
}

.fe-book:before {
    content: "\e921";
}

.fe-book-open:before {
    content: "\e922";
}

.fe-bookmark:before {
    content: "\e923";
}

.fe-box:before {
    content: "\e924";
}

.fe-briefcase:before {
    content: "\e925";
}

.fe-calendar:before {
    content: "\e926";
}

.fe-camera:before {
    content: "\e927";
}

.fe-camera-off:before {
    content: "\e928";
}

.fe-cast:before {
    content: "\e929";
}

.fe-check:before {
    content: "\e92a";
}

.fe-check-circle:before {
    content: "\e92b";
}

.fe-check-square:before {
    content: "\e92c";
}

.fe-chevron-down:before {
    content: "\e92d";
}

.fe-chevron-left:before {
    content: "\e92e";
}

.fe-chevron-right:before {
    content: "\e92f";
}

.fe-chevron-up:before {
    content: "\e930";
}

.fe-chevrons-down:before {
    content: "\e931";
}

.fe-chevrons-left:before {
    content: "\e932";
}

.fe-chevrons-right:before {
    content: "\e933";
}

.fe-chevrons-up:before {
    content: "\e934";
}

.fe-chrome:before {
    content: "\e935";
}

.fe-circle:before {
    content: "\e936";
}

.fe-clipboard:before {
    content: "\e937";
}

.fe-clock:before {
    content: "\e938";
}

.fe-cloud:before {
    content: "\e939";
}

.fe-cloud-drizzle:before {
    content: "\e93a";
}

.fe-cloud-lightning:before {
    content: "\e93b";
}

.fe-cloud-off:before {
    content: "\e93c";
}

.fe-cloud-rain:before {
    content: "\e93d";
}

.fe-cloud-snow:before {
    content: "\e93e";
}

.fe-code:before {
    content: "\e93f";
}

.fe-codepen:before {
    content: "\e940";
}

.fe-command:before {
    content: "\e941";
}

.fe-compass:before {
    content: "\e942";
}

.fe-copy:before {
    content: "\e943";
}

.fe-corner-down-left:before {
    content: "\e944";
}

.fe-corner-down-right:before {
    content: "\e945";
}

.fe-corner-left-down:before {
    content: "\e946";
}

.fe-corner-left-up:before {
    content: "\e947";
}

.fe-corner-right-down:before {
    content: "\e948";
}

.fe-corner-right-up:before {
    content: "\e949";
}

.fe-corner-up-left:before {
    content: "\e94a";
}

.fe-corner-up-right:before {
    content: "\e94b";
}

.fe-cpu:before {
    content: "\e94c";
}

.fe-credit-card:before {
    content: "\e94d";
}

.fe-crop:before {
    content: "\e94e";
}

.fe-crosshair:before {
    content: "\e94f";
}

.fe-database:before {
    content: "\e950";
}

.fe-delete:before {
    content: "\e951";
}

.fe-disc:before {
    content: "\e952";
}

.fe-dollar-sign:before {
    content: "\e953";
}

.fe-download:before {
    content: "\e954";
}

.fe-download-cloud:before {
    content: "\e955";
}

.fe-droplet:before {
    content: "\e956";
}

.fe-edit:before {
    content: "\e957";
}

.fe-edit-2:before {
    content: "\e958";
}

.fe-edit-3:before {
    content: "\e959";
}

.fe-external-link:before {
    content: "\e95a";
}

.fe-eye:before {
    content: "\e95b";
}

.fe-eye-off:before {
    content: "\e95c";
}

.fe-facebook:before {
    content: "\e95d";
}

.fe-fast-forward:before {
    content: "\e95e";
}

.fe-feather:before {
    content: "\e95f";
}

.fe-file:before {
    content: "\e960";
}

.fe-file-minus:before {
    content: "\e961";
}

.fe-file-plus:before {
    content: "\e962";
}

.fe-file-text:before {
    content: "\e963";
}

.fe-film:before {
    content: "\e964";
}

.fe-filter:before {
    content: "\e965";
}

.fe-flag:before {
    content: "\e966";
}

.fe-folder:before {
    content: "\e967";
}

.fe-folder-minus:before {
    content: "\e968";
}

.fe-folder-plus:before {
    content: "\e969";
}

.fe-git-branch:before {
    content: "\e96a";
}

.fe-git-commit:before {
    content: "\e96b";
}

.fe-git-merge:before {
    content: "\e96c";
}

.fe-git-pull-request:before {
    content: "\e96d";
}

.fe-github:before {
    content: "\e96e";
}

.fe-gitlab:before {
    content: "\e96f";
}

.fe-globe:before {
    content: "\e970";
}

.fe-grid:before {
    content: "\e971";
}

.fe-hard-drive:before {
    content: "\e972";
}

.fe-hash:before {
    content: "\e973";
}

.fe-headphones:before {
    content: "\e974";
}

.fe-heart:before {
    content: "\e975";
}

.fe-help-circle:before {
    content: "\e976";
}

.fe-home:before {
    content: "\e977";
}

.fe-image:before {
    content: "\e978";
}

.fe-inbox:before {
    content: "\e979";
}

.fe-info:before {
    content: "\e97a";
}

.fe-instagram:before {
    content: "\e97b";
}

.fe-italic:before {
    content: "\e97c";
}

.fe-layers:before {
    content: "\e97d";
}

.fe-layout:before {
    content: "\e97e";
}

.fe-life-buoy:before {
    content: "\e97f";
}

.fe-link:before {
    content: "\e980";
}

.fe-link-2:before {
    content: "\e981";
}

.fe-linkedin:before {
    content: "\e982";
}

.fe-list:before {
    content: "\e983";
}

.fe-loader:before {
    content: "\e984";
}

.fe-lock:before {
    content: "\e985";
}

.fe-log-in:before {
    content: "\e986";
}

.fe-log-out:before {
    content: "\e987";
}

.fe-mail:before {
    content: "\e988";
}

.fe-map:before {
    content: "\e989";
}

.fe-map-pin:before {
    content: "\e98a";
}

.fe-maximize:before {
    content: "\e98b";
}

.fe-maximize-2:before {
    content: "\e98c";
}

.fe-menu:before {
    content: "\e98d";
}

.fe-message-circle:before {
    content: "\e98e";
}

.fe-message-square:before {
    content: "\e98f";
}

.fe-mic:before {
    content: "\e990";
}

.fe-mic-off:before {
    content: "\e991";
}

.fe-minimize:before {
    content: "\e992";
}

.fe-minimize-2:before {
    content: "\e993";
}

.fe-minus:before {
    content: "\e994";
}

.fe-minus-circle:before {
    content: "\e995";
}

.fe-minus-square:before {
    content: "\e996";
}

.fe-monitor:before {
    content: "\e997";
}

.fe-moon:before {
    content: "\e998";
}

.fe-more-horizontal:before {
    content: "\e999";
}

.fe-more-vertical:before {
    content: "\e99a";
}

.fe-move:before {
    content: "\e99b";
}

.fe-music:before {
    content: "\e99c";
}

.fe-navigation:before {
    content: "\e99d";
}

.fe-navigation-2:before {
    content: "\e99e";
}

.fe-octagon:before {
    content: "\e99f";
}

.fe-package:before {
    content: "\e9a0";
}

.fe-paperclip:before {
    content: "\e9a1";
}

.fe-pause:before {
    content: "\e9a2";
}

.fe-pause-circle:before {
    content: "\e9a3";
}

.fe-percent:before {
    content: "\e9a4";
}

.fe-phone:before {
    content: "\e9a5";
}

.fe-phone-call:before {
    content: "\e9a6";
}

.fe-phone-forwarded:before {
    content: "\e9a7";
}

.fe-phone-incoming:before {
    content: "\e9a8";
}

.fe-phone-missed:before {
    content: "\e9a9";
}

.fe-phone-off:before {
    content: "\e9aa";
}

.fe-phone-outgoing:before {
    content: "\e9ab";
}

.fe-pie-chart:before {
    content: "\e9ac";
}

.fe-play:before {
    content: "\e9ad";
}

.fe-play-circle:before {
    content: "\e9ae";
}

.fe-plus:before {
    content: "\e9af";
}

.fe-plus-circle:before {
    content: "\e9b0";
}

.fe-plus-square:before {
    content: "\e9b1";
}

.fe-pocket:before {
    content: "\e9b2";
}

.fe-power:before {
    content: "\e9b3";
}

.fe-printer:before {
    content: "\e9b4";
}

.fe-radio:before {
    content: "\e9b5";
}

.fe-refresh-ccw:before {
    content: "\e9b6";
}

.fe-refresh-cw:before {
    content: "\e9b7";
}

.fe-repeat:before {
    content: "\e9b8";
}

.fe-rewind:before {
    content: "\e9b9";
}

.fe-rotate-ccw:before {
    content: "\e9ba";
}

.fe-rotate-cw:before {
    content: "\e9bb";
}

.fe-rss:before {
    content: "\e9bc";
}

.fe-save:before {
    content: "\e9bd";
}

.fe-scissors:before {
    content: "\e9be";
}

.fe-search:before {
    content: "\e9bf";
}

.fe-send:before {
    content: "\e9c0";
}

.fe-server:before {
    content: "\e9c1";
}

.fe-settings:before {
    content: "\e9c2";
}

.fe-share:before {
    content: "\e9c3";
}

.fe-share-2:before {
    content: "\e9c4";
}

.fe-shield:before {
    content: "\e9c5";
}

.fe-shield-off:before {
    content: "\e9c6";
}

.fe-shopping-bag:before {
    content: "\e9c7";
}

.fe-shopping-cart:before {
    content: "\e9c8";
}

.fe-shuffle:before {
    content: "\e9c9";
}

.fe-sidebar:before {
    content: "\e9ca";
}

.fe-skip-back:before {
    content: "\e9cb";
}

.fe-skip-forward:before {
    content: "\e9cc";
}

.fe-slack:before {
    content: "\e9cd";
}

.fe-slash:before {
    content: "\e9ce";
}

.fe-sliders:before {
    content: "\e9cf";
}

.fe-smartphone:before {
    content: "\e9d0";
}

.fe-speaker:before {
    content: "\e9d1";
}

.fe-square:before {
    content: "\e9d2";
}

.fe-star:before {
    content: "\e9d3";
}

.fe-stop-circle:before {
    content: "\e9d4";
}

.fe-sun:before {
    content: "\e9d5";
}

.fe-sunrise:before {
    content: "\e9d6";
}

.fe-sunset:before {
    content: "\e9d7";
}

.fe-tablet:before {
    content: "\e9d8";
}

.fe-tag:before {
    content: "\e9d9";
}

.fe-target:before {
    content: "\e9da";
}

.fe-terminal:before {
    content: "\e9db";
}

.fe-thermometer:before {
    content: "\e9dc";
}

.fe-thumbs-down:before {
    content: "\e9dd";
}

.fe-thumbs-up:before {
    content: "\e9de";
}

.fe-toggle-left:before {
    content: "\e9df";
}

.fe-toggle-right:before {
    content: "\e9e0";
}

.fe-trash:before {
    content: "\e9e1";
}

.fe-trash-2:before {
    content: "\e9e2";
}

.fe-trending-down:before {
    content: "\e9e3";
}

.fe-trending-up:before {
    content: "\e9e4";
}

.fe-triangle:before {
    content: "\e9e5";
}

.fe-truck:before {
    content: "\e9e6";
}

.fe-tv:before {
    content: "\e9e7";
}

.fe-twitter:before {
    content: "\e9e8";
}

.fe-type:before {
    content: "\e9e9";
}

.fe-umbrella:before {
    content: "\e9ea";
}

.fe-underline:before {
    content: "\e9eb";
}

.fe-unlock:before {
    content: "\e9ec";
}

.fe-upload:before {
    content: "\e9ed";
}

.fe-upload-cloud:before {
    content: "\e9ee";
}

.fe-user:before {
    content: "\e9ef";
}

.fe-user-check:before {
    content: "\e9f0";
}

.fe-user-minus:before {
    content: "\e9f1";
}

.fe-user-plus:before {
    content: "\e9f2";
}

.fe-user-x:before {
    content: "\e9f3";
}

.fe-users:before {
    content: "\e9f4";
}

.fe-video:before {
    content: "\e9f5";
}

.fe-video-off:before {
    content: "\e9f6";
}

.fe-voicemail:before {
    content: "\e9f7";
}

.fe-volume:before {
    content: "\e9f8";
}

.fe-volume-1:before {
    content: "\e9f9";
}

.fe-volume-2:before {
    content: "\e9fa";
}

.fe-volume-x:before {
    content: "\e9fb";
}

.fe-watch:before {
    content: "\e9fc";
}

.fe-wifi:before {
    content: "\e9fd";
}

.fe-wifi-off:before {
    content: "\e9fe";
}

.fe-wind:before {
    content: "\e9ff";
}

.fe-x:before {
    content: "\ea00";
}

.fe-x-circle:before {
    content: "\ea01";
}

.fe-x-square:before {
    content: "\ea02";
}

.fe-zap:before {
    content: "\ea03";
}

.fe-zap-off:before {
    content: "\ea04";
}

.fe-zoom-in:before {
    content: "\ea05";
}

.fe-zoom-out:before {
    content: "\ea06";
}

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../iconfonts/glyphicons/fonts/glyphicons-halflings-regular.eot');
    src: url('../iconfonts/glyphicons/fonts/glyphicons-halflings-regular.eot?#iefix') format("embedded-opentype"), url('../iconfonts/glyphicons/fonts/glyphicons-halflings-regular.woff2') format("woff2"), url('../iconfonts/glyphicons/fonts/glyphicons-halflings-regular.woff') format("woff"), url('../iconfonts/glyphicons/fonts/glyphicons-halflings-regular.ttf') format("truetype"), url('../iconfonts/glyphicons/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format("svg");
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
    content: "\2a";
}

.glyphicon-plus:before {
    content: "\2b";
}

.glyphicon-euro:before,
.glyphicon-eur:before {
    content: "\20ac";
}

.glyphicon-minus:before {
    content: "\2212";
}

.glyphicon-cloud:before {
    content: "\2601";
}

.glyphicon-envelope:before {
    content: "\2709";
}

.glyphicon-pencil:before {
    content: "\270f";
}

.glyphicon-glass:before {
    content: "\e001";
}

.glyphicon-music:before {
    content: "\e002";
}

.glyphicon-search:before {
    content: "\e003";
}

.glyphicon-heart:before {
    content: "\e005";
}

.glyphicon-star:before {
    content: "\e006";
}

.glyphicon-star-empty:before {
    content: "\e007";
}

.glyphicon-user:before {
    content: "\e008";
}

.glyphicon-film:before {
    content: "\e009";
}

.glyphicon-th-large:before {
    content: "\e010";
}

.glyphicon-th:before {
    content: "\e011";
}

.glyphicon-th-list:before {
    content: "\e012";
}

.glyphicon-ok:before {
    content: "\e013";
}

.glyphicon-remove:before {
    content: "\e014";
}

.glyphicon-zoom-in:before {
    content: "\e015";
}

.glyphicon-zoom-out:before {
    content: "\e016";
}

.glyphicon-off:before {
    content: "\e017";
}

.glyphicon-signal:before {
    content: "\e018";
}

.glyphicon-cog:before {
    content: "\e019";
}

.glyphicon-trash:before {
    content: "\e020";
}

.glyphicon-home:before {
    content: "\e021";
}

.glyphicon-file:before {
    content: "\e022";
}

.glyphicon-time:before {
    content: "\e023";
}

.glyphicon-road:before {
    content: "\e024";
}

.glyphicon-download-alt:before {
    content: "\e025";
}

.glyphicon-download:before {
    content: "\e026";
}

.glyphicon-upload:before {
    content: "\e027";
}

.glyphicon-inbox:before {
    content: "\e028";
}

.glyphicon-play-circle:before {
    content: "\e029";
}

.glyphicon-repeat:before {
    content: "\e030";
}

.glyphicon-refresh:before {
    content: "\e031";
}

.glyphicon-list-alt:before {
    content: "\e032";
}

.glyphicon-lock:before {
    content: "\e033";
}

.glyphicon-flag:before {
    content: "\e034";
}

.glyphicon-headphones:before {
    content: "\e035";
}

.glyphicon-volume-off:before {
    content: "\e036";
}

.glyphicon-volume-down:before {
    content: "\e037";
}

.glyphicon-volume-up:before {
    content: "\e038";
}

.glyphicon-qrcode:before {
    content: "\e039";
}

.glyphicon-barcode:before {
    content: "\e040";
}

.glyphicon-tag:before {
    content: "\e041";
}

.glyphicon-tags:before {
    content: "\e042";
}

.glyphicon-book:before {
    content: "\e043";
}

.glyphicon-bookmark:before {
    content: "\e044";
}

.glyphicon-print:before {
    content: "\e045";
}

.glyphicon-camera:before {
    content: "\e046";
}

.glyphicon-font:before {
    content: "\e047";
}

.glyphicon-bold:before {
    content: "\e048";
}

.glyphicon-italic:before {
    content: "\e049";
}

.glyphicon-text-height:before {
    content: "\e050";
}

.glyphicon-text-width:before {
    content: "\e051";
}

.glyphicon-align-left:before {
    content: "\e052";
}

.glyphicon-align-center:before {
    content: "\e053";
}

.glyphicon-align-right:before {
    content: "\e054";
}

.glyphicon-align-justify:before {
    content: "\e055";
}

.glyphicon-list:before {
    content: "\e056";
}

.glyphicon-indent-left:before {
    content: "\e057";
}

.glyphicon-indent-right:before {
    content: "\e058";
}

.glyphicon-facetime-video:before {
    content: "\e059";
}

.glyphicon-picture:before {
    content: "\e060";
}

.glyphicon-map-marker:before {
    content: "\e062";
}

.glyphicon-adjust:before {
    content: "\e063";
}

.glyphicon-tint:before {
    content: "\e064";
}

.glyphicon-edit:before {
    content: "\e065";
}

.glyphicon-share:before {
    content: "\e066";
}

.glyphicon-check:before {
    content: "\e067";
}

.glyphicon-move:before {
    content: "\e068";
}

.glyphicon-step-backward:before {
    content: "\e069";
}

.glyphicon-fast-backward:before {
    content: "\e070";
}

.glyphicon-backward:before {
    content: "\e071";
}

.glyphicon-play:before {
    content: "\e072";
}

.glyphicon-pause:before {
    content: "\e073";
}

.glyphicon-stop:before {
    content: "\e074";
}

.glyphicon-forward:before {
    content: "\e075";
}

.glyphicon-fast-forward:before {
    content: "\e076";
}

.glyphicon-step-forward:before {
    content: "\e077";
}

.glyphicon-eject:before {
    content: "\e078";
}

.glyphicon-chevron-left:before {
    content: "\e079";
}

.glyphicon-chevron-right:before {
    content: "\e080";
}

.glyphicon-plus-sign:before {
    content: "\e081";
}

.glyphicon-minus-sign:before {
    content: "\e082";
}

.glyphicon-remove-sign:before {
    content: "\e083";
}

.glyphicon-ok-sign:before {
    content: "\e084";
}

.glyphicon-question-sign:before {
    content: "\e085";
}

.glyphicon-info-sign:before {
    content: "\e086";
}

.glyphicon-screenshot:before {
    content: "\e087";
}

.glyphicon-remove-circle:before {
    content: "\e088";
}

.glyphicon-ok-circle:before {
    content: "\e089";
}

.glyphicon-ban-circle:before {
    content: "\e090";
}

.glyphicon-arrow-left:before {
    content: "\e091";
}

.glyphicon-arrow-right:before {
    content: "\e092";
}

.glyphicon-arrow-up:before {
    content: "\e093";
}

.glyphicon-arrow-down:before {
    content: "\e094";
}

.glyphicon-share-alt:before {
    content: "\e095";
}

.glyphicon-resize-full:before {
    content: "\e096";
}

.glyphicon-resize-small:before {
    content: "\e097";
}

.glyphicon-exclamation-sign:before {
    content: "\e101";
}

.glyphicon-gift:before {
    content: "\e102";
}

.glyphicon-leaf:before {
    content: "\e103";
}

.glyphicon-fire:before {
    content: "\e104";
}

.glyphicon-eye-open:before {
    content: "\e105";
}

.glyphicon-eye-close:before {
    content: "\e106";
}

.glyphicon-warning-sign:before {
    content: "\e107";
}

.glyphicon-plane:before {
    content: "\e108";
}

.glyphicon-calendar:before {
    content: "\e109";
}

.glyphicon-random:before {
    content: "\e110";
}

.glyphicon-comment:before {
    content: "\e111";
}

.glyphicon-magnet:before {
    content: "\e112";
}

.glyphicon-chevron-up:before {
    content: "\e113";
}

.glyphicon-chevron-down:before {
    content: "\e114";
}

.glyphicon-retweet:before {
    content: "\e115";
}

.glyphicon-shopping-cart:before {
    content: "\e116";
}

.glyphicon-folder-close:before {
    content: "\e117";
}

.glyphicon-folder-open:before {
    content: "\e118";
}

.glyphicon-resize-vertical:before {
    content: "\e119";
}

.glyphicon-resize-horizontal:before {
    content: "\e120";
}

.glyphicon-hdd:before {
    content: "\e121";
}

.glyphicon-bullhorn:before {
    content: "\e122";
}

.glyphicon-bell:before {
    content: "\e123";
}

.glyphicon-certificate:before {
    content: "\e124";
}

.glyphicon-thumbs-up:before {
    content: "\e125";
}

.glyphicon-thumbs-down:before {
    content: "\e126";
}

.glyphicon-hand-right:before {
    content: "\e127";
}

.glyphicon-hand-left:before {
    content: "\e128";
}

.glyphicon-hand-up:before {
    content: "\e129";
}

.glyphicon-hand-down:before {
    content: "\e130";
}

.glyphicon-circle-arrow-right:before {
    content: "\e131";
}

.glyphicon-circle-arrow-left:before {
    content: "\e132";
}

.glyphicon-circle-arrow-up:before {
    content: "\e133";
}

.glyphicon-circle-arrow-down:before {
    content: "\e134";
}

.glyphicon-globe:before {
    content: "\e135";
}

.glyphicon-wrench:before {
    content: "\e136";
}

.glyphicon-tasks:before {
    content: "\e137";
}

.glyphicon-filter:before {
    content: "\e138";
}

.glyphicon-briefcase:before {
    content: "\e139";
}

.glyphicon-fullscreen:before {
    content: "\e140";
}

.glyphicon-dashboard:before {
    content: "\e141";
}

.glyphicon-paperclip:before {
    content: "\e142";
}

.glyphicon-heart-empty:before {
    content: "\e143";
}

.glyphicon-link:before {
    content: "\e144";
}

.glyphicon-phone:before {
    content: "\e145";
}

.glyphicon-pushpin:before {
    content: "\e146";
}

.glyphicon-usd:before {
    content: "\e148";
}

.glyphicon-gbp:before {
    content: "\e149";
}

.glyphicon-sort:before {
    content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
    content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
    content: "\e152";
}

.glyphicon-sort-by-order:before {
    content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
    content: "\e154";
}

.glyphicon-sort-by-attributes:before {
    content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
    content: "\e156";
}

.glyphicon-unchecked:before {
    content: "\e157";
}

.glyphicon-expand:before {
    content: "\e158";
}

.glyphicon-collapse-down:before {
    content: "\e159";
}

.glyphicon-collapse-up:before {
    content: "\e160";
}

.glyphicon-log-in:before {
    content: "\e161";
}

.glyphicon-flash:before {
    content: "\e162";
}

.glyphicon-log-out:before {
    content: "\e163";
}

.glyphicon-new-window:before {
    content: "\e164";
}

.glyphicon-record:before {
    content: "\e165";
}

.glyphicon-save:before {
    content: "\e166";
}

.glyphicon-open:before {
    content: "\e167";
}

.glyphicon-saved:before {
    content: "\e168";
}

.glyphicon-import:before {
    content: "\e169";
}

.glyphicon-export:before {
    content: "\e170";
}

.glyphicon-send:before {
    content: "\e171";
}

.glyphicon-floppy-disk:before {
    content: "\e172";
}

.glyphicon-floppy-saved:before {
    content: "\e173";
}

.glyphicon-floppy-remove:before {
    content: "\e174";
}

.glyphicon-floppy-save:before {
    content: "\e175";
}

.glyphicon-floppy-open:before {
    content: "\e176";
}

.glyphicon-credit-card:before {
    content: "\e177";
}

.glyphicon-transfer:before {
    content: "\e178";
}

.glyphicon-cutlery:before {
    content: "\e179";
}

.glyphicon-header:before {
    content: "\e180";
}

.glyphicon-compressed:before {
    content: "\e181";
}

.glyphicon-earphone:before {
    content: "\e182";
}

.glyphicon-phone-alt:before {
    content: "\e183";
}

.glyphicon-tower:before {
    content: "\e184";
}

.glyphicon-stats:before {
    content: "\e185";
}

.glyphicon-sd-video:before {
    content: "\e186";
}

.glyphicon-hd-video:before {
    content: "\e187";
}

.glyphicon-subtitles:before {
    content: "\e188";
}

.glyphicon-sound-stereo:before {
    content: "\e189";
}

.glyphicon-sound-dolby:before {
    content: "\e190";
}

.glyphicon-sound-5-1:before {
    content: "\e191";
}

.glyphicon-sound-6-1:before {
    content: "\e192";
}

.glyphicon-sound-7-1:before {
    content: "\e193";
}

.glyphicon-copyright-mark:before {
    content: "\e194";
}

.glyphicon-registration-mark:before {
    content: "\e195";
}

.glyphicon-cloud-download:before {
    content: "\e197";
}

.glyphicon-cloud-upload:before {
    content: "\e198";
}

.glyphicon-tree-conifer:before {
    content: "\e199";
}

.glyphicon-tree-deciduous:before {
    content: "\e200";
}

.glyphicon-cd:before {
    content: "\e201";
}

.glyphicon-save-file:before {
    content: "\e202";
}

.glyphicon-open-file:before {
    content: "\e203";
}

.glyphicon-level-up:before {
    content: "\e204";
}

.glyphicon-copy:before {
    content: "\e205";
}

.glyphicon-paste:before {
    content: "\e206";
}

.glyphicon-alert:before {
    content: "\e209";
}

.glyphicon-equalizer:before {
    content: "\e210";
}

.glyphicon-king:before {
    content: "\e211";
}

.glyphicon-queen:before {
    content: "\e212";
}

.glyphicon-pawn:before {
    content: "\e213";
}

.glyphicon-bishop:before {
    content: "\e214";
}

.glyphicon-knight:before {
    content: "\e215";
}

.glyphicon-baby-formula:before {
    content: "\e216";
}

.glyphicon-tent:before {
    content: "\26fa";
}

.glyphicon-blackboard:before {
    content: "\e218";
}

.glyphicon-bed:before {
    content: "\e219";
}

.glyphicon-apple:before {
    content: "\f8ff";
}

.glyphicon-erase:before {
    content: "\e221";
}

.glyphicon-hourglass:before {
    content: "\231b";
}

.glyphicon-lamp:before {
    content: "\e223";
}

.glyphicon-duplicate:before {
    content: "\e224";
}

.glyphicon-piggy-bank:before {
    content: "\e225";
}

.glyphicon-scissors:before {
    content: "\e226";
}

.glyphicon-bitcoin:before,
.glyphicon-btc:before,
.glyphicon-xbt:before {
    content: "\e227";
}

.glyphicon-yen:before,
.glyphicon-jpy:before {
    content: "\00a5";
}

.glyphicon-ruble:before,
.glyphicon-rub:before {
    content: "\20bd";
}

.glyphicon-scale:before {
    content: "\e230";
}

.glyphicon-ice-lolly:before {
    content: "\e231";
}

.glyphicon-ice-lolly-tasted:before {
    content: "\e232";
}

.glyphicon-education:before {
    content: "\e233";
}

.glyphicon-option-horizontal:before {
    content: "\e234";
}

.glyphicon-option-vertical:before {
    content: "\e235";
}

.glyphicon-menu-hamburger:before {
    content: "\e236";
}

.glyphicon-modal-window:before {
    content: "\e237";
}

.glyphicon-oil:before {
    content: "\e238";
}

.glyphicon-grain:before {
    content: "\e239";
}

.glyphicon-sunglasses:before {
    content: "\e240";
}

.glyphicon-text-size:before {
    content: "\e241";
}

.glyphicon-text-color:before {
    content: "\e242";
}

.glyphicon-text-background:before {
    content: "\e243";
}

.glyphicon-object-align-top:before {
    content: "\e244";
}

.glyphicon-object-align-bottom:before {
    content: "\e245";
}

.glyphicon-object-align-horizontal:before {
    content: "\e246";
}

.glyphicon-object-align-left:before {
    content: "\e247";
}

.glyphicon-object-align-vertical:before {
    content: "\e248";
}

.glyphicon-object-align-right:before {
    content: "\e249";
}

.glyphicon-triangle-right:before {
    content: "\e250";
}

.glyphicon-triangle-left:before {
    content: "\e251";
}

.glyphicon-triangle-bottom:before {
    content: "\e252";
}

.glyphicon-triangle-top:before {
    content: "\e253";
}

.glyphicon-console:before {
    content: "\e254";
}

.glyphicon-superscript:before {
    content: "\e255";
}

.glyphicon-subscript:before {
    content: "\e256";
}

.glyphicon-menu-left:before {
    content: "\e257";
}

.glyphicon-menu-right:before {
    content: "\e258";
}

.glyphicon-menu-down:before {
    content: "\e259";
}

.glyphicon-menu-up:before {
    content: "\e260";
}

.icon-icon-shape {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    text-align: center;
    i {
        font-size: 24px;
        line-height: 2;
    }
}